import { Injectable } from '@angular/core';
import { Category, Subcategory } from '@app/api/models';
import { ProfessionalsService } from '@app/api/services';
import { toArray } from '@app/core/functions/string.functions';
import { SearchFilterFormValue } from '@app/search/interfaces/search-filter-form-value.interface';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SearchListParams } from '@app/api/models/search-list-params';

@Injectable()
export class SearchFilterStateConverter {
    constructor(private readonly professionalsApi: ProfessionalsService,
    ) {}

    public getSearchFilterState(params: SearchListParams): Observable<SearchFilterFormValue> {
        if (!params) {
            return of();
        }

        return forkJoin({
            ...(!!params.categories && {
                categories: this.getCategories(toArray(params.categories)?.map(idStr => parseInt(idStr, 10)))
            }),
            ...(!!params.subcategories && {
                subcategories: this.getSubcategories(toArray(params.subcategories)?.map(idStr => parseInt(idStr, 10)))
            }),
            forСall: of(null)
        }).pipe(
            map(({categories, subcategories}) => {

                    const searchFilterState: SearchFilterFormValue = {
                        ...(params?.query && { query: params?.query }),
                        location: {
                            country: params?.country,
                            city: params?.city
                        },
                        categoryAndSubcategory: {
                            categories,
                            subcategories
                        },
                        rangeDate: {
                            startDatetime: params?.startDatetime,
                            endDatetime: params?.endDatetime,
                            exactDatetime: params?.exactDatetime,
                        },
                        rangePrice: {
                            startPrice: Number(params?.startPrice) || null,
                            endPrice:  Number(params?.endPrice) || null,
                            priceCurrency: params?.priceCurrency,
                            paymentMethods: params?.paymentMethods?.split(',') as ['cash', 'online'],
                        },
                        profTraits: {
                            onlyWithReviews: params?.onlyWithReviews,
                            onlyWithCertificates: params?.onlyWithCertificates,
                            startAge: params?.startAge,
                            endAge: params?.endAge,
                            nationalities: Number(params?.nationalities) || null,
                            languages: params?.languages?.split(','),
                            professionalLevel: params?.professionalLevel ?? void 0,
                        },
                        isInstantBooking: params?.onlyWithAutoOrderConfirmation,
                        serviceTypes: params?.serviceTypes?.split(','),
                        onlyWithPhotos: params?.onlyWithPhotos,
                        onlyWithFixedPrice: params?.onlyWithFixedPrice,
                        experience: params?.experienceFrom,

                        tags: void 0,
                        isOnlineBooking: void 0,
                        rating: params?.ratingFrom ? Number(params?.ratingFrom) : void 0,
                    };

                    return searchFilterState;
                },
            ),
        );
    }

    public getSearchListParams(data: SearchFilterFormValue): SearchListParams {
        if (!data) {
            return;
        }

        const {
            categoryAndSubcategory,
            rangeDate,
            query,
            rangePrice,
            profTraits,
            location,
            isInstantBooking,
            serviceTypes,
            onlyWithPhotos,
            onlyWithFixedPrice,
            experience,
        } = data;
        const subcategories = categoryAndSubcategory?.subcategories?.map(item => item?.id?`${item.id}`: null);
        const categories = categoryAndSubcategory?.categories?.map(item => item?.id?`${item.id}`: null);

        return {
            query,
            ...rangeDate,
            ...rangePrice,
            ...profTraits,
            ...location,

            startPrice: rangePrice?.startPrice?.toString(),
            endPrice: rangePrice?.endPrice?.toString(),
            nationalities: profTraits?.nationalities?.toString(),

            categories: categories?.join(',') || null,
            subcategories: subcategories?.join(',') || null,
            paymentMethods: rangePrice?.paymentMethods?.join(','),
            languages: profTraits?.languages?.join(',') || null,
            serviceTypes: serviceTypes?.join(',') || null,

            onlyWithAutoOrderConfirmation: isInstantBooking || null,
            onlyWithPhotos: onlyWithPhotos || null,
            onlyWithFixedPrice: onlyWithFixedPrice || null,
            experienceFrom: experience,

            tags: void 0,
            region: void 0,
            subregion: void 0,
            postalCode: void 0,
            district: void 0,
            gender: void 0,
            maxDistance: void 0,
            longitude: null,
            latitude: null,
        };
    }

    private getCategories(ids: number[]): Observable<Category[]> {
        if(!ids) {
            return of([]);
        }

        return forkJoin(ids.map(id => this.professionalsApi.professionalsCategoriesRead(id)));
    }

    private getSubcategories(ids: number[]): Observable<Subcategory[]> {
        if(!ids) {
            return of([]);
        }

        return forkJoin(ids.map(id => this.professionalsApi.professionalsSubcategoriesRead(id)));
    }
}
