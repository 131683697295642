import { Injectable } from '@angular/core';
import { DateStepDataInterface } from '@app/book/interfaces/date-step-data-interface';
import { DatetimeStepDataInterface } from '@app/book/interfaces/datetime-step-data-interface';
import { ClientDetailsStepDataInterface } from '@app/book/interfaces/client-details-step-data-interface';
import { ServiceList } from '@app/api/models/service-list';

@Injectable({
    providedIn: 'root',
})
export class BookingStateService {
    private data: {
        service?: ServiceList;
        direct?: boolean;
        0?: DateStepDataInterface;
        1?: DatetimeStepDataInterface;
        2?: ClientDetailsStepDataInterface;
    } = {};

    public reset(): void {
        this.data = {};
    }

    public isDirect(): boolean {
        return this.data.direct === undefined ? false : this.data.direct;
    }

    public setIsDirect(v: boolean): void {
        this.data.direct = v;
    }

    public setService(service: ServiceList): void {
        this.data.service = service;
    }

    public getService(): ServiceList {
        return this.data.service;
    }

    public setDateStep(date: Date): void {
        this.data['0'] = { date };
    }
    public getDateStep(): DateStepDataInterface | null {
        return this.data['0'] ? this.data['0'] : null;
    }

    public setDateTime(datetime: Date): void {
        this.data['1'] = { datetime };
    }
    public getDateTime(): Date | null {
        return this.data['1']?.datetime ? this.data['1'].datetime : null;
    }

    public setInterval(interval: { start: Date; end: Date }): void {
        this.data['1'] = { interval };
    }

    public getInterval(): { start: Date; end: Date } | null {
        return this.data['1']?.interval ? this.data['1'].interval : null;
    }

    public setClientDetails(details: ClientDetailsStepDataInterface): void {
        this.data['2'] = details;
    }
    public getClientDetails(): ClientDetailsStepDataInterface | null {
        return this.data['2'];
    }

    public isThirdStepReady(): boolean {
        return this.data?.service && (Boolean(this.data['1']?.datetime) || Boolean(this.data['1'].interval));
    }
}
