export const environment = {
    production: false,
    backend: {
        auth: '/api/auth/token/',
        media: '/media/photos/',
        certificate: '',
        cities: '/api/location/cites/',
        population_treshold: 100000,
        communication_messages_received: '/api/communication/messages/received/',
        communication_messages_sent: '/api/communication/messages/sent/',
        communication_devices_fcm: '/api/communication/devices/fcm/',
        contact: '/api/contacts/contacts/',
        countries: '/api/location/countries/',
        districts: '/api/location/districts/',
        education: '/api/accounts/professional-educations/',
        experience: '/api/accounts/professional-experience/',
        certificates: '/api/accounts/professional-certificates/',
        language: '/api/location/languages/',
        user_language: '/api/accounts/languages/',
        location: '/api/accounts/locations/',
        master: '/api/accounts/professionals/',
        master_list: '/api/professionals/professionals/',
        plugin: '',
        reset_password_link: '/api/accounts/send-resetFilters-password-link/',
        reset_password: '/api/accounts/resetFilters-password/',
        refresh: '/api/auth/token/',
        register: '/api/accounts/register/',
        user: '/api/accounts/profile/',
        user_contact: '/api/accounts/contacts/',
        user_plugin: '',
        user_settings: '/api/accounts/settings/',
        regions: '/api/location/regions/',
        subregions: '/api/location/subregions/',
        send_verify_registration: '/api/accounts/resend-verify-registration/',
        subcategory: '/api/professionals/subcategories/',
        category: '/api/professionals/categories/',
        verify_registration: '/api/accounts/verify-registration/',
        professional_contact: '/api/accounts/professional-contacts/',
        professional_tags: '/api/accounts/professional-tags/',
        professional_tags_list: '/api/professionals/tags/',
        professional_location: '/api/accounts/professional-locations/',
        reviews: '/api/accounts/reviews/',
        reviews_readonly: '/api/communication/reviews',
        saved_professionals: '/api/accounts/saved-professionals/',
        service_prices: '/api/accounts/service-prices/',
        services: '/api/accounts/services/',
        service_tag: '/api/accounts/service-tags/',
        service_location: '/api/accounts/service-locations/',
        service_photo: '/api/accounts/service-photos/',
        service_schedule: '/api/accounts/service-schedule/',
        is_user_registered: '/api/accounts/is-user-registered/',
        register_email: '/api/accounts/register-email/',
        postal_codes: '/api/location/postal-codes/',
        rates: '/api/rates/',
        latest_messages: '/api/communication/messages/latest/',
        messages_list: '/api/communication/messages/list/',
        messages_sent: '/api/communication/messages/sent/',
        fcm_devices: '/api/communication/devices/fcm/',
        master_photos: '/api/accounts/professional-photos/',
        master_schedule: '/api/accounts/professional-schedule/',
        services_readonly: '/api/services/services/',
        service_tag_readonly: '/api/services/tags/',
        master_photos_readonly: '/api/professionals/professional-photos/',
        sent_orders: '/api/accounts/orders/sent/',
        received_orders: '/api/accounts/orders/received/',
        calendar: '/api/schedule/calendar/',
        service_schedule_set: '/api/accounts/service-schedule/set/',
        master_schedule_set: '/api/accounts/professional-schedule/set/',
        url: 'https://app.maxibooking.ru:8000',
        payment_system: 'https://backend.servicesales.ru/en/payments/payment-system/form/'
    },
    client_id: 'FpJXMu69syeuHeUV1tr9yD9hkmCQB4gyCeW5rOlU',
    client_secret: 'GfSHJvGzVLKhvZUKpmJB9ZQ9NMT1sLoxjyv1ljfd7ie5XR707HUq8zUOPH91aplhRpWZI76qIBmNeyIPpLflvy1lW9wrGv50Z0BONLbW9FJSgylGpQY4A0nK2S0dGfhX',
    refresh_token_expire_time: 3600,
    calendar_day_intervals: 4,
    map_url: 'https://core-renderer-tiles.maps.yandex.net/tiles?l=map&x={x}&y={y}&z={z}&scale=1&lang=ru_RU',
    map_crs: 'EPSG3395',
    nominatim_reverse_url: 'https://nominatim.openstreetmap.org/reverse',
    nominatim_search_url: 'https://nominatim.openstreetmap.org/search',
    firebaseConfig: {
        apiKey: 'AIzaSyBeOZGKwhC5S6AiioaY9w9kh56GVH_hNPA',
        authDomain: 'd8btest.firebaseapp.com',
        databaseURL: 'https://d8btest.firebaseio.com',
        projectId: 'd8btest',
        storageBucket: 'd8btest.appspot.com',
        messagingSenderId: '252850429498',
        vapidKey: 'BJqNDHlAWKT_b_vrtERKJuC9-g27uJ1VQq6lbMQ5mGVKLPSPdw1U6glQZFvFsT-psLvvdjK5H8MMLRoC5ALmrnU',
        appId: '1:252850429498:web:5a2874e1ca6689a2921f03'
    },
    message: {
        direct_update_interval_ms: 10000,
        chat_list_update_interval_ms: 10000,
        background_update_interval_ms: 10000,
        messages_per_page: 50
    },
    sentry: {
        enabled: false,
        dsn: 'https://dd67cfc8136047b9b1a523e48ba80bc2@o495055.ingest.sentry.io/5567186'
    },
    contacts: {
        facebook: 'logo-facebook',
        instagram: 'logo-instagram',
        web: 'globe-outline',
        skype: 'logo-skype',
        phone: 'call-outline',
        whatsapp: 'logo-whatsapp',
        email: 'mail-outline',
    },
    ga_id: 'UA-219645153-1',
    ga_id_com: '',
    ya_id: '87422090',
    analytics_activity_ms: 60000,
    geolacationServiceUrl: {
        ExtremeIpLookupCom: `https://extreme-ip-lookup.com/json/?key=5zNEeXSQ5JFtpFp8jIBX`,
        IpApiCom: "https://ip-api.com/json",
        IpwhoisApp: "https://ipwhois.app/json/",
        IpNf: "https://ip.nf/me.json",
        ApiIpdataCo: `https://api.ipdata.co/?api-key=650902cc01a9a3a7cff619b38c0c9ef70b8faf08522fb680d0bd0abd`,
        IpApiCo: "https://ipapi.co/json/",
    },
    geolocationServiceUrl: {
        ExtremeIpLookupCom: `https://extreme-ip-lookup.com/json/?key=5zNEeXSQ5JFtpFp8jIBX`,
        IpApiCom: "https://ip-api.com/json",
        IpwhoisApp: "https://ipwhois.app/json/",
        IpNf: "https://ip.nf/me.json",
        ApiIpdataCo: `https://api.ipdata.co/?api-key=650902cc01a9a3a7cff619b38c0c9ef70b8faf08522fb680d0bd0abd`,
        IpApiCo: "https://ipapi.co/json/",
    },

    respondList: {
        max: 50,
        step: 5,
    },
    blog_page_size: 12,
    separator: "-",
    yandex_speller: 'https://speller.yandex.net/services/spellservice.json/checkText',
    sber_doc: 'https://securepayments.sberbank.ru/wiki/doku.php/start',
    youtube_api: 'https://www.youtube.com/iframe_api',
};
