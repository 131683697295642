import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ProfessionalCalendar } from '@app/api/models';
import { ScheduleService } from '@app/api/services';
import { addDays, getLocalDateString } from '@app/core/functions/datetime.functions';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper-service';
import { NgDestroyService } from '@app/core/services';
import { CalendarWorkerService } from '@app/shared/components/calendar-component/calendar-worker.service';
import { configuration } from '@conf/configuration';
import { CalendarUnit } from './calendar-unit';
import { CalendarService } from './calendar.service';

@Component({
    selector: 'app-calendar-component',
    templateUrl: './calendar-component.component.html',
    styleUrls: ['./calendar-component.component.scss'],
    providers: [
        CalendarService,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CalendarComponentComponent),
            multi: true,
        },
    ],
})
export class CalendarComponentComponent implements OnInit {

    public loading = false;
    public readonly currentlyViewedDate$ = new BehaviorSubject<Date>(null);

    @Input() public flexibleServiceLen: number; //minutes
    @Output() public readonly timePicked = new EventEmitter<Date>();
    @Output() private readonly pickDateEvent = new EventEmitter<void>();
    @Output() private readonly intervalPicked = new EventEmitter<{ start: Date; end: Date }>();
    @Input() private readonly professionalId: number;
    @Input() private readonly serviceId: number;

    constructor(
        private readonly calendar: CalendarService,
        private readonly modalController: ModalController,
        private readonly scheduleApi: ScheduleService,
        private readonly helper: HelperService,
        private readonly destroy$: NgDestroyService,
        public readonly calendarWorker: CalendarWorkerService
    ) {

    }

    public ngOnInit(): void {
        this.initCalendar();
    }

    @Input()
    public set selectedDate(date: Date) {
        this.currentlyViewedDate$.next(date ?? new Date());
    }

    public getButtonText(data: CalendarUnit): string {
        if (data.enabled) {
            return data.datetime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        }

        return '—';
    }

    public getSelectedDateStr(): string {
        return this.helper.getStrMonthFullDate(this.currentlyViewedDate$.value);
    }

    public pickDate(): void {
        this.pickDateEvent.emit(void 0);
    }

    public setDatetime(unit: CalendarUnit | null): void {
        if (!!this.flexibleServiceLen) {
            this.calendarWorker.selectInterval(unit, this.flexibleServiceLen);
            const interval = this.calendarWorker.getInterval();
            if (interval) {
                this.intervalPicked.emit(interval);
            }
        } else {
            this.calendarWorker.selectSingleTime(unit?.datetime);
            this.timePicked.emit(unit?.datetime);
        }
    }

    public shiftBackDisabled(): boolean {
        return this.currentlyViewedDate$.value.toDateString() === (new Date()).toDateString();
    }

    public shiftDate(offset: number): void {
        const newDate = addDays(this.currentlyViewedDate$.value, offset);
        this.currentlyViewedDate$.next(newDate);
        this.setDatetime(null);
    }

    public getUnitColor(unit: CalendarUnit): string {
        if (!unit.enabled || unit?.interval?.disabled) {
            return 'light';
        }
        if (unit?.interval?.start) {
            return 'success';
        }

        return (unit.selected || unit?.interval?.selected) ? 'primary' : 'dark';
    }

    public getUnitFill(unit: CalendarUnit): string {
        if (!unit.enabled) {
            return 'solid';
        }

        return (unit.selected || unit?.interval?.selected) ? 'solid' : 'outline';
    }

    private initCalendar(): void {
        this.currentlyViewedDate$.pipe(
            takeUntil(this.destroy$),
            tap(() => this.loading = true),
            switchMap(startDate => {
                const endDate = addDays(startDate, 1);

                return !this.professionalId || !this.serviceId
                    ? of<ProfessionalCalendar[]>(null)
                    : this.scheduleApi.scheduleCalendarList({
                        service: this.serviceId?.toString(),
                        professional: this.professionalId?.toString(),
                        startDatetime: getLocalDateString(startDate),
                        endDatetime: getLocalDateString(endDate),
                    });
            }),
            map(list =>
                this.calendar.generate(configuration.default_calendar_interval, list, !!this.flexibleServiceLen)),
            tap(() => this.loading = false),
            catchError(() => {
                this.loading = false;

                return of([]);
            }),
        ).subscribe(calendar => this.calendarWorker.setIntervals(calendar));
    }
}
