import { Analytics } from '@app/api/models/analytics';
import localeFrench from '@angular/common/locales/fr';
import localeGerman from '@angular/common/locales/de';
import localeCanada from '@angular/common/locales/en';
import localeSpain from '@angular/common/locales/es';
import localeGreek from '@angular/common/locales/el';
import localeArabic from '@angular/common/locales/ar';
import localeTurkish from '@angular/common/locales/tr';
import localeKorean from '@angular/common/locales/ko';
import localePt from '@angular/common/locales/pt';

export const configuration = {
    company: 'Capel Software Limited',
    app_title: "ServiceSalesApp",
    agreement_date: "2023-07-10",
    start_year_project: '2023',
    default_lang: 'en',
    lang_list: ['en', 'de', 'fr', 'es', 'pt', 'el', 'tr', 'ar', 'ko'],
    default_calendar_interval: 15,
    calendar_interval: 15,
    minutes_duration: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
    social: {
        vk: {
            icon: "assets/images/social/Vkontakte-Original.svg",
            icon_gray: "assets/images/social/vk-gray.svg",
            visible: false,
            url: "https://vk.com/vkservicesales"
        },
        facebook: {
            icon: "assets/images/social/Facebook-Original.svg",
            icon_gray: "assets/images/social/Facebook-Original.svg",
            visible: true,
            url: "https://www.facebook.com/servicesalesappcom"
        },
        instagram: {
            icon: "assets/images/social/Instagram-Original.svg",
            icon_gray: "assets/images/social/Instagram-Original.svg",
            visible: true,
            url: "https://www.instagram.com/servicesalesappcom_ie/"
        },
        telegram: {
            icon: "assets/images/social/tg.png",
            icon_gray: "assets/images/social/telegram-gray.svg",
            visible: true,
            url: ""
        },
        linkedin: {
            icon: "assets/images/social/LinkedIn-Original.svg",
            icon_gray: "assets/images/social/LinkedIn-Original.svg",
            visible: true,
            url: ""
        },
        twitter: {
            icon: "assets/images/social/Twitter-Original.svg",
            icon_gray: "assets/images/social/Twitter-Original.svg",
            visible: true,
            url: ""
        },
        youtube: {
            icon: "assets/images/social/YouTube-Original.svg",
            icon_gray: "assets/images/social/YouTube-Original.svg",
            visible: true,
            url: ""
        },
        tiktok: {
            icon: "assets/images/social/tiktok-svgrepo-com.svg",
            visible: true,
            url: "https://www.tiktok.com/@servicesalesapp.com"
        }
    },
    hasPaymentSystem: false,
    analytics: [],
    currency_list: ['CAD', 'EUR', 'USD'],
    default_currency: 'USD',
    listOfSkippedСountryIDs: [2963597, 2635167],
    locales: [
        { locale: localeCanada, id: 'en' },
        { locale: localeFrench, id: 'fr' },
        { locale: localeSpain, id: 'es' },
        { locale: localeGerman, id: 'de' },
        { locale: localeGreek, id: 'el' },
        { locale: localeArabic, id: 'ar' },
        { locale: localeTurkish, id: 'tr' },
        { locale: localeKorean, id: 'ko' },
        { locale: localePt, id: 'pt' },
    ],
    servicePublish: {
        dayCount: 8,
        hourCount: 24
    },
    contacts: {
        telegramPrefix: "https://t.me/",
        whatsappPrefix: "https://wa.me/",
        facebookPrefix: "https://www.facebook.com/",
        instagramPrefix: "https://www.instagram.com/",
        vkPrefix: "https://vk.com/",
        mailPrefix: "mailto:",
        phonePrefix: "tel:",
    },
    professional: {
        name: "professional-name"
    },
    price: {
        symbol: {
            start: 'symbol-narrow',
            end: 'symbol-narrow'
        }
    },
    hostname: location.origin
}
