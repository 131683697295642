import { Injectable } from '@angular/core';
import { ProfessionalCalendar } from '@app/api/models';
import { addMinutes, stripTime } from '@app/core/functions/datetime.functions';
import { environment } from '@env/environment';
import { CalendarInterval } from './calendar-interval';
import { CalendarUnit } from './calendar-unit';

interface Interval {
    start: Date;
    end: Date;
}

@Injectable()
export class CalendarService {
    private readonly minutesInDay = 1440;
    private readonly intervals = environment.calendar_day_intervals;
    private readonly minutesInInterval = this.minutesInDay / this.intervals;

    public generate(stepSize: number, enabledPeriods: ProfessionalCalendar[], isFlexible: boolean): CalendarInterval[] {
        if (!Number.isInteger(this.minutesInInterval / stepSize)) {
            throw Error('cannot generate calendar with given interval');
        }
        if (!Array.isArray(enabledPeriods) || !enabledPeriods.length) {
            return [];
        }
        const calendar: CalendarInterval[] = [];
        const openedPeriodArray: Interval[] = this.generateEnabledPeriodsArray(enabledPeriods);
        const day = stripTime(openedPeriodArray[0].start);
        let firstEnabledFlag = false;

        for (let intervals = 0; intervals < this.intervals; intervals += 1) {
            const units: CalendarUnit[] = [];
            for (
                let minutes = intervals * this.minutesInInterval;
                minutes <= (intervals + 1) * this.minutesInInterval;
                minutes += stepSize
            ) {
                const en = this.isEnabled(openedPeriodArray, day, minutes, isFlexible);
                if (en) {
                    firstEnabledFlag = true;
                }
                if (firstEnabledFlag) {
                    units.push({
                        datetime: addMinutes(day, minutes),
                        enabled: en,
                    });
                }
            }
            if (!units.length) {
                continue;
            }
            const startStr = units[0].datetime.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
            });
            const endStr = addMinutes(units[units.length - 1].datetime, -1).toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
            });

            if (units.length > 1 && units.filter(this.unitsFilter).length > 0) {
                let extend = [];
                if (units.length < 8) {
                    const additionalEmptyUnitsNumber = 7 - units.length;
                    extend = this.getEmptyPeriodsFromLast(units[units.length - 1], additionalEmptyUnitsNumber);
                }
                calendar.push(
                    { title: `${startStr} - ${endStr}`, units: [...units, ...extend] },
                );
            }
        }

        return calendar;
    }

    private unitsFilter(unit, index, list): boolean {
        //only today
        const today = index > 0 && unit.datetime.getDate() === list[index -1].datetime.getDate();

        return unit.enabled && today;
    }

    private getEmptyPeriodsFromLast(fromUnit: CalendarUnit, num: number): CalendarUnit[] {
        const ret: CalendarUnit[] = [];

        for(let i = 1; i < num + 1; ++i) {
            ret.push({
                enabled: false,
                datetime: addMinutes(fromUnit.datetime, this.minutesInInterval * i),
            });
        }

        return ret;
    }

    private isEnabled(enabledPeriods: Interval[], day: Date, minutes: number, isFlexible: boolean): boolean {
        const time = addMinutes(day, minutes);
        if ((new Date).getTime() > time.getTime()) {
            return false;
        }
        if (isFlexible) {
            return enabledPeriods.some(period => time >= period.start && time <= period.end);
        }

        return enabledPeriods.some(period => time >= period.start && time < period.end);
    }

    private generateEnabledPeriodsArray(enabledPeriods: ProfessionalCalendar[]): Interval[] {
        return enabledPeriods.map(v => ({
            start: new Date(v.start_datetime),
            end: new Date(v.end_datetime),
        }));
    }
}
