import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ResolvedUserLocation } from '@app/core/interfaces/user-location.interface';
import { NavParams, PopoverController } from '@ionic/angular';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';

@Component({
    selector: 'app-on-map-popover',
    templateUrl: './on-map-popover.component.html',
    styleUrls: ['./on-map-popover.component.scss'],
})
export class OnMapPopoverComponent implements OnDestroy {
    public city: FormControl;
    public country: FormControl;
    public data: ResolvedUserLocation;

    private readonly subscription: Subscription;

    constructor(
        private readonly navParams: NavParams,
        private readonly popover: PopoverController,
        private readonly fb: FormBuilder,
    ) {
        const data = this.navParams.get<ResolvedUserLocation>('data');
        this.city = this.fb.control(data?.city);
        this.country = this.fb.control(data?.country);
        this.subscription = this.country.valueChanges.pipe(tap(d => {
            if(!d || this.city.value?.country !== d.id) {
                this.city.reset();
            }
        })).subscribe();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public async submit(): Promise<void> {
        const returnData: ResolvedUserLocation = {
            city: this.city.value,
            country: this.country.value,
        };

        await this.popover.dismiss(returnData);
    }

    public async dismiss(): Promise<void> {
        await this.popover.dismiss();
    }
}
