import { Injectable } from '@angular/core';
import { CalendarInterval } from '@app/shared/components/calendar-component/calendar-interval';
import { CalendarUnit } from '@app/shared/components/calendar-component/calendar-unit';
import { configuration } from '@conf/configuration';

@Injectable()
export class CalendarWorkerService { // yes, it's very bad

    public timetable: CalendarInterval[];
    private intervalStart: Date;
    private intervalEnd: Date;
    private readonly timetableMap = new Map<string, CalendarUnit>();

    public setIntervals(timetable: CalendarInterval[]): void {
        this.timetable = timetable;
        timetable.forEach((d, i) =>
            d.units.forEach((u, j) => {
                if (i === 0 || j !== 0) {
                    this.timetableMap.set(`${i}_${j}`, u);
                }
            }));
    }

    public selectSingleTime(unit: Date | null): void {
        this.timetable.forEach(data => data.units.forEach(u => u.selected = u.datetime.getTime() === unit?.getTime()));
    }

    public getInterval(): { start: Date; end: Date } | null {
        return !!this.intervalStart && !!this.intervalEnd ? { start: this.intervalStart, end: this.intervalEnd } : null;
    }

    public selectInterval(unit: CalendarUnit | null, serviceLen: number): void {
        if (!unit) {
            return this.reset();
        }
        if (this.intervalStart && this.intervalStart.getTime() === unit.datetime.getTime()) {
            return this.reset();
        }
        if (!this.intervalStart || (this.intervalStart && this.intervalEnd)) {
            this.intervalStart = unit.datetime;
            this.intervalEnd = null;
            this.timetableMap.forEach(u =>
                u.interval = { selected: u.datetime.getTime() === unit?.datetime?.getTime() });
            this.generateIntervalStart(unit.datetime, serviceLen);

            return this.generate();
        }
        if (this.intervalStart) {
            if (unit?.interval?.start) {
                this.intervalEnd = unit.datetime;
                this.generateIntervalEnd(unit);
                this.generate();
            }
        }
    }

    private generate(): void {
        for (let i = 0; i < this.timetable.length; ++i) {
            for (let j = 0; j < this.timetable[i].units.length; ++j) {
                if (i !== 0 && j === 0) {
                    this.timetable[i].units[j] =
                        this.timetableMap.get(`${i - 1}_${this.timetable[i - 1].units.length - 1}`);
                } else {
                    this.timetable[i].units[j] = this.timetableMap.get(`${i}_${j}`);
                }
            }
        }
    }

    private reset(): void {
        this.timetable.forEach(data => data.units.forEach(u => {
            u.selected = false;
            u.interval = undefined;
        }));
        this.intervalStart = null;
        this.intervalEnd = null;
    }

    private generateIntervalEnd(unit: CalendarUnit): void {
        this.timetable.forEach(data => data.units.forEach(u => {
            const t = u.datetime.getTime();
            if (t >= this.intervalStart.getTime() && t <= unit.datetime.getTime()) {
                u.interval = { selected: true };
            } else {
                u.interval = undefined;
            }
        }));
    }

    private generateIntervalStart(
        selectedTime: Date,
        serviceLen: number
    ): void {
        const map = Array.from(this.timetableMap);
        for (let i = 0; i < map.length; ++i) {
            const eoi = this.isEndOfInterval(
                i, map, serviceLen / configuration.calendar_interval);

            const mapIdx = map[i][0];
            const u = this.timetableMap.get(mapIdx);

            if (selectedTime <= map[i][1].datetime && eoi) {
                u.interval.start = true;
            } else {
                if (
                    map[i - 1] && (map[i - 1][1]?.interval?.start || map[i - 1][1]?.interval?.selected)
                    && map[i - 1][1]?.datetime.getTime() === u.datetime.getTime()
                ) {
                    u.interval.start = true;
                } else if (u.datetime.getTime() !== selectedTime.getTime()) {
                    u.interval.disabled = true;
                }
            }
            this.timetableMap.set(mapIdx, u);
        }
    }

    private isEndOfInterval(currentIndex: number, units: [string, CalendarUnit][], intervalLen: number): boolean {
        if (currentIndex < intervalLen) {
            return false;
        }

        for (let i = currentIndex; i >= currentIndex - intervalLen; --i) {
            if (!units[i][1].enabled) {
                return false;
            }
        }

        return units[currentIndex - intervalLen][1]?.interval?.start
            || units[currentIndex - intervalLen][1]?.interval?.selected;
    }
}
